/* Override Ant Design's default styles for the switch component */
.ant-switch:not(.ant-switch-checked) .ant-switch-inner {
  background-color: #f54f5d;
}

.ant-switch .ant-switch-inner {
  background-color: #54d868; /* Change inner background color */
}

.dashboard-calender,
.dashboard-calender .ant-picker-panel {
  background-color: #c3e5eb;
}

.end-dates .ant-picker-panel-container {
  margin-left: -50px !important;
  background: #c3e5eb;
}

.start-dates .ant-picker-panel-container {
  background: #c3e5eb;
}

.start-time-picker .ant-picker-dropdown,
.ant-picker-footer,
.ant-picker-time-panel {
  background: #c3e5eb;
}

:where(
    .css-dev-only-do-not-override-zisvbv
  ).ant-select-single.ant-select-lg.ant-select-open
  .ant-select-selection-item {
  color: rgba(0, 0, 0, 0.25);
}

:where(.css-dev-only-do-not-override-zisvbv).ant-select-dropdown
  .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: black;
}

.no-data-text {
  color: black !important;
}
