@import url('https://fonts.googleapis.com/css2?family=Nunito+Sans:ital,opsz,wght@0,6..12,200..1000;1,6..12,200..1000&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

body{
    font-family: "Nunito Sans", sans-serif;
    height: -webkit-fill-available;
}

html{
    height: -webkit-fill-available;
}
