/* Styles for the timePicerk for the start TIme */

.start-date-picker .ant-picker {
  font-size: 0.875rem; /* Equivalent to text-sm */
  color: #8657fa; /* Equivalent to text-[#8657FA] */
  background-color: #e4d9fe; /* Equivalent to bg-[#E4D9FE] */
  padding: 0.5rem 0.5rem; /* Equivalent to px-4 py-2 */
  line-height: 1; /* Equivalent to leading-none */
  font-weight: bold; /* Equivalent to font-bold */
  border-radius: 9999px; /* Equivalent to rounded-full */
  width: 100px;
  height: 30px;
}

/* Styles for the timePicerk for the end TIme */

.end-date-picker .ant-picker {
  font-size: 0.875rem; /* Equivalent to text-sm */
  color: #4ab0ff; /* Your desired color */
  background-color: #d7eeff; /* Your desired background color */
  padding: 0.5rem 0.5rem; /* Equivalent to px-4 py-2 */
  line-height: 1; /* Equivalent to leading-none */
  font-weight: bold; /* Equivalent to font-bold */
  border-radius: 9999px; /* Equivalent to rounded-full */
  width: 100px;

  height: 30px;
  /* Add any additional styles as needed */
}
.start-time-picker .ant-picker {
  font-size: 0.875rem; /* Equivalent to text-sm */
  color: #8657fa; /* Equivalent to text-[#8657FA] */
  background-color: #e4d9fe; /* Equivalent to bg-[#E4D9FE] */
  padding: 0.5rem 0.5rem; /* Equivalent to px-4 py-2 */
  line-height: 1; /* Equivalent to leading-none */
  font-weight: bold; /* Equivalent to font-bold */
  border-radius: 9999px; /* Equivalent to rounded-full */
  /* width: 100%; */
  height: 30px;
}

/* Styles for the timePicerk for the end TIme */

.end-time-picker .ant-picker {
  font-size: 0.875rem; /* Equivalent to text-sm */
  color: #4ab0ff; /* Your desired color */
  background-color: #d7eeff; /* Your desired background color */
  padding: 0.5rem 1rem; /* Equivalent to px-4 py-2 */
  line-height: 1; /* Equivalent to leading-none */
  font-weight: bold; /* Equivalent to font-bold */
  border-radius: 9999px; /* Equivalent to rounded-full */
  /* width: 100px; */

  height: 30px;
  /* Add any additional styles as needed */
}

.date-picker
  .ant-picker
  .ant-picker-outlined.css-dev-only-do-not-override-7u62po {
  font-size: 0.875rem; /* Equivalent to text-sm */
  color: #8657fa; /* Equivalent to text-[#8657FA] */
  background-color: #e4d9fe; /* Equivalent to bg-[#E4D9FE] */
  padding: 0.5rem 1rem; /* Equivalent to px-4 py-2 */
  line-height: 1; /* Equivalent to leading-none */
  font-weight: bold; /* Equivalent to font-bold */
  border-radius: 9999px; /* Equivalent to rounded-full */
  width: 100px;
  height: 30px;
}

.smallCalendar {
  width: 50px;
}

.customDropdown .ant-picker-dropdown {
  top: unset !important;
  bottom: calc(100% + 8px) !important;
}

.end-dates .ant-picker-panel-container {
  margin-left: -50px !important;
}
